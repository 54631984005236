"use client";

import type Branch from "branch-sdk";
import type { ComponentProps } from "react";
import { createContext } from "react";

export const BranchContext = createContext<{
  link: (linkProps: Branch.DeepLinkData) => Promise<string>;
}>({
  link: () => Promise.resolve(""),
});

export const BranchProvider = ({
  children,
  ...props
}: Omit<ComponentProps<typeof BranchContext.Provider>, "value">) => {
  const link = async (linkProps: Branch.DeepLinkData) => {
    const response = await fetch("https://api2.branch.io/v1/url", {
      method: "POST",
      body: JSON.stringify({
        branch_key: process.env.NEXT_PUBLIC_BRANCH_KEY,
        ...linkProps,
      }),
    });
    const body = await response.json();
    if (response.ok) return body.url;
    throw body.error.message;
  };

  return (
    <BranchContext.Provider value={{ link }} {...props}>
      {children}
    </BranchContext.Provider>
  );
};
