"use client";

import { Slider } from "@packages/ui";
import {
  numericPercentages,
  radius,
  semanticColors,
  spacing,
  zIndices,
} from "@packages/ui/global/stylex/vars.stylex";
import * as stylex from "@stylexjs/stylex";
import { NProgress } from "@tanem/react-nprogress";
import { useRouter } from "next/navigation";
import { useEffect, useOptimistic, useTransition } from "react";

const styles = stylex.create({
  slider: {
    height: spacing.xxs,
    left: 0,
    pointerEvents: "none",
    position: "fixed",
    right: 0,
    top: 0,
    width: numericPercentages[100],
    zIndex: zIndices.loading,
  },
  done: {
    display: "none",
  },
  noRadius: {
    borderRadius: radius.none,
  },
});

export const NavigationLoading = () => {
  const router = useRouter();
  // useOptimistic is a useState hook that reverts to the optimistic (given -- here, false) state
  // whenever an action completes
  const [loading, setLoading] = useOptimistic<boolean>(false);
  const [, startTransition] = useTransition();

  useEffect(() => {
    // patch the router.push function to enable showing the loading state
    if (router.push.name === "patched") return; // we've already done this
    const push = router.push;
    router.push = function patched(...args) {
      // we don't need to start a transition here because App Router navigations are always inside transitions
      setLoading(true);
      return push.apply(history, args);
    };
  }, []);

  const popStateHandler = () => {
    startTransition(() => setLoading(true));
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("popstate", popStateHandler);
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("popstate", popStateHandler);
      }
    };
  }, []);

  return (
    <NProgress isAnimating={loading}>
      {({ progress, isFinished }) => (
        <Slider
          value={progress * 100}
          styleXArray={[styles.slider, isFinished ? styles.done : null]}
          size={"small"}
          color={semanticColors.accentHigh}
          rangeStyleXArray={[styles.noRadius]}
          trackStyleXArray={[styles.noRadius]}
        />
      )}
    </NProgress>
  );
};
